body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #333333;
  color: #333;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.goal-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.goal-input {
  display: flex;
  align-items: center;
}

.goal-input input[type="text"] {
  margin: 0 10px;
}

.goal-input span {
  margin: 0 5px;
}




h1 {
  font-size: 32px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: #333 solid 1px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

th {
  background-color: #d46810;
  color: #fff;
}

input[type="text"] {
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  color: black;
  border-radius: 4px;
}

select{
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  color: black;
  border-radius: 4px;
}

input[type="number"] {
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  color: black;
  border-radius: 4px;
}

.name-input,
.hours-input {
  background-color: #e0e0e0;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 5px 0;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #d46810;
  transition: background-color 0.3s;
}


button:hover {
  background-color: #974c0f;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.message {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.message.success {
  color: green;
}

.message.error {
  color: red;
}

.spg {
  display: flex;
  flex-direction: column; /* Ændrer retningen til en lodret kolonne */
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.spg .question {
  width: 100%; /* Får spørgsmålene til at fylde hele bredden af .spg */
  margin-bottom: 5px; /* Giver lidt luft mellem spørgsmålene */
}

.spg label {
  font-weight: bold;
  display: block; /* Sørger for at label vises over textarea */
}

textarea {
  width: 100%; /* Gør textarea til at fylde hele bredden af sin container */
  height: 38px; /* Fast højde for textarea */
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  color: black;
  border-radius: 4px;
  padding: 10px; /* Giver lidt indvendig luft i textarea */
  box-sizing: border-box; /* Sikrer, at padding ikke øger textarea's bredde */
}


.radio-buttons {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.5em;
}

.radio-buttons label {
  margin-right: 20px;
  display: inline-block;
}

.radio-buttons input[type="radio"] {
  display: none; /* Hide the actual radio input */
}

.radio-buttons label {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.radio-buttons label:hover {
  background-color: #ddd;
}

.radio-buttons input[type="radio"]:checked + label {
  background-color: #d46810;
  color: #fff;
  border-color: #d46810;
}

.highlight {
  background-color: #aaaaaa; /* Lys grå baggrundsfarve */
}


/* Responsive styling */
@media (max-width: 768px) {
  body {
    padding: 10px;
    width: 100%;
  }

  .container {
    padding: 15px;
    width: 100%;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }

  th, td {
    padding: 8px;
    padding-bottom: 0px !important;
  }

  button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .radio-buttons {
    font-size: 1.2em;
  }

  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    display: none;
  }

  tbody tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
  }

  tbody td {
    border: none;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }

  tbody td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    padding-left: 10px;
    font-weight: bold;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .input-table, .results-table {
    margin-bottom: 0;
  }

  /* Specific adjustments to remove left margin */
  .input-table tbody td,
  .results-table tbody td {
    padding-left: 10px;
  }
}
